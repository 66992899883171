:root {
  --app-height: 100%;
}


html {
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  // --scroll-behavior: smooth !important;
  // scroll-behavior: smooth !important;

  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  font-family: "Inter", "Arial", sans-serif;
}

.MuiMenu-list {
  li {
    img {
      display: none;
    }
  }
}
.MuiInputAdornment-positionEnd{
  height: 100% !important;
  padding-left: 5px;
  border-left: 2px solid #ccc;
  color: #000 !important;
}

.MuiDrawer-paperAnchorRight{
  min-width: 400px;
  position: absolute !important;
  // top: 160px;
  box-shadow: none;
  // padding-bottom: 160px;
}

// .MuiModal-backdrop{
//   top: 160px;
// }

.MuiPagination-ul {
  border-radius: 12px;
  // background: #FFF;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
  li {
    display: flex;
    align-items: center;
    height: 40px;
   
    button {
      margin: 0;
      font-weight: 600;
      height: 100%;
    }

    div{
      margin: 0;
      font-weight: 600;
      height: 100%;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-radius: 12px;

      button {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }

    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      button {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .Mui-selected {
    background-color: #0C88C6 !important;
    color: #fff !important;
  }
}


.MuiDrawer-paperAnchorBottom{
  border-radius: 24px 24px 0px 0px !important;
  max-height: 80%;
  padding-bottom: 10px;
}

.MuiBadge-badge{
  border-radius: 6px;
  font-weight: 600;
}
.MuiMenu-paper{
  // transform: translateY(-40px) !important;
  border-radius: 16px;

  .btns{
    button{
      background:transparent;
      border: none;
      color: rgba(157, 160, 169, 1);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    .active{
      color: rgba(12, 136, 198, 1);
    }
  }
}

.swiper{
  height: 100%;
}
.swiper-wrapper{
  height: 100%;
}
.swiper-slide{
  height: 100%;
}
.swiper-slide a{
  height: 100%;
}

.MuiModal-backdrop{
  top: 135px;
}

@media (max-width:992px) {
  .MuiModal-backdrop{
    top: 0;
  }
}


.MuiButtonArrow{
  transition: 0.1s all ease;

  &:hover{
    background: #0C88C6 !important;
    button{
      color: #FFF !important;
      
    }

    svg{
      path{
        fill: #fff;
      }
    }
  }

  button{
    &:disabled{
      opacity: 0.3 !important;
      cursor: not-allowed;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  font-family: "Inter", "Arial", sans-serif;
  // overflow: visible !important;
}

// nextjs-portal {
//   display: none;
// }


*,
*::after,
*::before {
  box-sizing: inherit;
}

.visuallyhidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
}

.focusShadow {
  outline: none;

  &:focus {
    box-shadow: 0 0 1px 1px rgba(7, 143, 205, 255),
      0 0 1px 2px rgba(255, 255, 255, 0.9);
  }
}

// *:focus-visible {
//   outline: 1px dashed #479AFF;
//   outline-offset: 2px;
// }


// NOTE - number input ni chiziqchalarini ko'rsatmaslik
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes open-opacity {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes open-fade-up {
  from {
    transform: translateY(-5px);
  }
  to {
    transform: translateY(0px);
  }
}

// ----------------------------- MARGIN & PADDING -----------------------
.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

// ----------------- INTER FONTS --------------
/* inter-300 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-800 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-900 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('../../Assets/fonts/inter-v13-cyrillic_latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

// ----------------- POPPINS FONTS --------------
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 300;
//   src: url('/../../Assets/fonts/poppins-v20-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   src: url('/../../Assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: italic;
//   font-weight: 400;
//   src: url('/../../Assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 500;
//   src: url('/../../Assets/fonts/poppins-v20-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 600;
//   src: url('/../../Assets/fonts/poppins-v20-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 700;
//   src: url('/../../Assets/fonts/poppins-v20-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 800;
//   src: url('/../../Assets/fonts/poppins-v20-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }
// @font-face {
//   font-display: swap;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 900;
//   src: url('/../../Assets/fonts/poppins-v20-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
// }

// ----------------- MONTSERRAT FONTS --------------
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../../Assets/fonts/montserrat-v25-latin-600.woff2") format("woff2"),
  /* Chrome 36+, Opera 23+, Firefox 39+ */
  url("../../Assets/fonts/montserrat-v25-latin-600.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../../Assets/fonts/montserrat-v25-latin-600.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
    url("../../Assets/fonts/montserrat-v25-latin-600.woff") format("woff");
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// -------------------------------- MODAL -------------------------------
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--close-icon {
  margin-bottom: 0.5rem;
  text-align: end;

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}


.MuiDialogContentText-root {
  font-size: 20px !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  color: #151515 !important;
}

.MuiDialogTitle-root {
  display: none;
}

.MuiDialogActions-root {
  width: 100% !important;
  display: flex !important;
  gap: 10px !important;
  justify-content: space-between !important;
  margin-bottom: 0 !important;

  button {
    background-color: transparent;
    color: #0c88c6 !important;
    border: 1px solid rgba(7, 143, 205, 255);
    font-size: 1rem;
    display: flex;
    width: 100%;
    max-width: 13.625rem;
    padding: 0.375rem 1.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    text-transform: uppercase;

    &:hover {
      background-color: rgba(7, 143, 205, 255);
      color: #fff !important;
    }
  }

  button:first-child {
    border: 0;
  }

  // button:first-child {
  //   visibility: hidden;

  //   &:after {
  //     content: "Yo'q";
  //     visibility: visible;
  //     display: block;
  //     position: absolute;
  //     background-color: transparent;
  //     color: #0c88c6 !important;
  //     border: 1px solid rgba(7, 143, 205, 255);
  //     font-size: 1rem;
  //     width: 100%;
  //     max-width: 13.625rem;
  //     padding: 0.375rem 1.5625rem;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 0.625rem;
  //     text-transform: uppercase;

  //     &:hover {
  //       background-color: rgba(7, 143, 205, 255) !important;
  //       color: #fff !important;
  //     }
  //   }
  // }
  // button:last-child {
  //   visibility: hidden;

  //   &:after {
  //     content: "Ha";
  //     visibility: visible;
  //     display: block;
  //     position: absolute;
  //     background-color: transparent;
  //     color: #0c88c6 !important;
  //     border: 1px solid rgba(7, 143, 205, 255);
  //     font-size: 1rem;
  //     width: 100%;
  //     max-width: 13.625rem;
  //     padding: 0.375rem 1.5625rem;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 0.625rem;
  //     text-transform: uppercase;

  //     &:hover {
  //       background-color: rgba(7, 143, 205, 255) !important;
  //       color: #fff !important;
  //     }
  //   }
  // }
}